import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import * as vars from './../vars.js';
import Button from 'react-bootstrap/Button';
//import Carousel from 'react-bootstrap/Carousel';

import { motion, useAnimate } from "framer-motion"



function About(props) {


  var [render, setRender] = useState(0);

  
  useEffect(() => {




  });


  

  return (
    <div className="page">


      <div className="center_box">
        <div className="center_box_heading">
        ABOUT US

        </div>

        <div className="center_box_text">
        <h5>Serving Regina since 1975</h5>
        <p>Rudy's Brakes & Steering has been family owned and operated since 1975, offering complete automotive repairs, with Art and Faye having taken over the business in 1998.</p>
        <br/>
        <h5>All Makes & Models</h5>
        <p>Our shop services all vehicle makes and models with our friendly and experienced journeyman mechanics.</p>
        <br/>
        <h5>Our Technicians Offer Many Services</h5>
        <p><a className="red_link" href="/services">Learn more.</a></p>
        </div>
        <div className="classic_img" />

      </div>
    
    </div>);

}
  
export default About;