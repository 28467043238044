import React, {useState, useEffect} from 'react';


import Header from './components/Header';
import Footer from './components/Footer';



import Message from './components/Message';

import About from './components/About';
import Services from './components/Services';
import HoursAndLocation from './components/HoursAndLocation';


import logo from './logo.svg';
import './App.css';

import * as vars from './vars.js';
import * as funcs from './funcs.js';

import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";



import { ReactSession } from 'react-client-session';

var doOnce=false;

function App() {


  const [render, setRender] = useState(0);


 


  useEffect(() => {

  //console.log('window.location.search: '+window.location.search);

    (async function() {

      if(!doOnce) {
        doOnce=true;

       
        setRender(render+1);

      }
    })();

    
    
  });

  return (
    <div className="center_site">
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/message/:obj" element={<><Message /></>} />
          <Route path="/" element={<><About /></>} />
          <Route path="/services" element={<><Services /></>} />
          <Route path="/hours_and_location" element={<><HoursAndLocation /></>} />
          
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );


  
}

export default App;
