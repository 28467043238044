import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

import * as vars from './../vars.js';

import { ReactSession } from 'react-client-session';

const google = window.google;

var cats = [];
for(var i=0;i<vars.pages.length;i++) {
  var page = vars.pages[i];
  if(!cats[page.category]) {
    cats[page.category]=[];
  }
  cats[page.category].push(page);
}


function Footer(props) {

 const [categories, setCategories] = useState([]);


  useEffect(() => {
    
    //console.log(cats);
    
    setCategories(cats);

        // Initialize and add the map
      let map;

      async function initMap() { // 50.451765098921186, -104.59544351028585
        const position = { lat: 50.451765098921186, lng: -104.59544351028585 };
        // Request needed libraries.
        //@ts-ignore
        const { Map } = await google.maps.importLibrary("maps");
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

        // The map, centered at Uluru
        map = new Map(document.getElementById("map"), {
          zoom: 14,
          center: position,
          mapId: "ActionExpressHQ_Map",
        });

        // The marker, positioned at Uluru
        const marker = new AdvancedMarkerElement({
          map: map,
          position: position,
          title: "AE_HQ",
        });
      }
      
       initMap();
    
  });

  function clicked(name) {



  }


  return (<>
  <div className="footer_box">

    <div className="full_footer">
      
      {Object.keys(categories).map((category, index) => {
          if(category!='false') {
            return (<div key={index} className={"list left_list_"+index}><ul>
                    <li><span className="list_head"><a className="white_link" href={(cats[category][0].name==category?cats[category][0].href:undefined)}>{category}</a></span></li>

                    {vars.pages.map((page, index) => {
                       if(page.footer && page.category == category && page.name != category && ((props.account && page.logged_in_only) || (!props.account && page.logged_out_only) || (!page.logged_in_only && !page.logged_out_only))) {
                        return (<li key={index}><a className="white_link" onClick={()=>clicked(page.name)} href={page.href}>{page.name}</a></li>);
                      }
                    })}
                  </ul></div>);
          }
          
        })}

      <div id="map">
      </div>
      <div className="footer_address">

      <span className="thick">Rudy's Brakes & Steering (1998) Ltd.</span><br/>
      1700 Winnipeg Street<br/>
      Regina, SK<br/>
      S4P 1E9<br/><br/>
      <span className="footer_phone"><a className="white_link" href="tel://1-306-525-0559">1.306.525.0559</a></span>
      <span className="footer_email"><br/><a className="white_link" href="mailto:rudysbrakes@sasktel.net">rudysbrakes@sasktel.net</a></span>
      </div>

      <div className="footer_logo">
      </div>
    </div>
  

    <div className="mobile_footer">

      <img style={{cursor: 'pointer'}} src="/images/logos/left_logo2.png" onClick={()=>{window.open("/", '_self')}} width="300" /><br/><br/>
        

        <p>1700 Winnipeg Street, Regina, SK - S4P 1E9</p><br/>
        
        <p><a className="white_link footer_phone" href="tel://1-306-525-0559">1.306.525.0559</a></p>
        <p><a className="white_link footer_email" href="mailto:rudysbrakes@sasktel.net" >rudysbrakes@sasktel.net</a></p><br/>
        <br/>

        <div className="no-print">
        {Object.keys(categories).map((category, index) => {
          if(category!='false') {
            return (<div key={index} className={"mobile_list mobile_footer_list_"+index}><ul>
                    <div className="list_head"><a className="white_link" href={(cats[category][0].name==category?cats[category][0].href:undefined)}>{category}</a></div>
                    {vars.pages.map((page, index) => {
                      if(page.footer && page.category == category && page.name != category && ((props.account && page.logged_in_only) || (!props.account && page.logged_out_only) || (!page.logged_in_only && !page.logged_out_only))) {
                      
                        return (<li key={index}><a className="white_link"  onClick={()=>clicked(page.name)} href={page.href}>{page.name}</a></li>);
                      }
                    })}
                  </ul></div>);
          }
          
        })}
        
        </div>

    

    </div>
  </div>

  <div className="copyright">Copyright 2023, Rudy's Brakes & Steering (1998) Ltd.</div>
  <br/><br/>

  </>);

}
  
export default Footer;