import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import * as vars from './../vars.js';
import Button from 'react-bootstrap/Button';
//import Carousel from 'react-bootstrap/Carousel';

import { motion, useAnimate } from "framer-motion"



function Services(props) {


  var [render, setRender] = useState(0);

  
  useEffect(() => {




  });


  

  return (
    <div className="page">


      <div className="center_box">
        <div className="center_box_heading">
        ABOUT US

        </div>

        <div className="center_box_text">
              <p><i>Our technicians offer a comprehensive set of services including but not limited to the following:</i></p><br/>

      
              <h5><a class="red_link" onclick="$('#wheel_alignments').slideToggle();">Wheel Alignments</a></h5>
              <div id="wheel_alignments">
                <p>If your car feels like it is pulling, if you need to correct the steering lots, the steering wheel is crooked, or if the tires have uneven wear get a front end check and a wheel alignment.</p><br/>
              </div><script>$('#wheel_alignments').hide();</script>
                      <h5><a class="red_link" onclick="$('#steering_and_suspension').slideToggle();">Steering and Suspension</a></h5>
              <div id="steering_and_suspension">
                <p>Worn and loose steering and suspension parts can cause clunks and bangs, moans and groans, make your car feel like it wanders all over the road, and cause tires to have uneven wear and to cup. These parts should be greased and inspected regularly to prevent fatigue and breakage.</p><br/>
              </div><script>$('#steering_and_suspension').hide();</script>
                      <h5><a class="red_link" onclick="$('#tune-ups').slideToggle();">Tune-ups</a></h5>
              <div id="tune-ups">
                <p>Getting a tuneup helps keep your car healthy, running longer, better fuel mileage, and keeps the engine running more efficiently. If your vehicle feels like it is running rough, feels sluggish or the engine sounds like it is "missing", then you should have the tune checked.</p><br/>
              </div><script>$('#tune-ups').hide();</script>
                      <h5><a class="red_link" onclick="$('#diagnostics').slideToggle();">Diagnostics</a></h5>
              <div id="diagnostics">
                <p>Includes checking codes for warning lights on in the dash, reading the codes to see what the vehicle requires, and clearing the codes. Some codes are self explanatory, others lead us in the direction of finding the problem.</p><br/>
              </div><script>$('#diagnostics').hide();</script>
                      <h5><a class="red_link" onclick="$('#engine_repair').slideToggle();">Engine Repair</a></h5>
              <div id="engine_repair">
                <p>Includes fixing oil leaks, diagnosing how the vehicle is running and appropriate repairs.</p><br/>
              </div><script>$('#engine_repair').hide();</script>
                      <h5><a class="red_link" onclick="$('#brake_service__abs_and_wheel_bearings').slideToggle();">Brake Service, ABS and Wheel Bearings</a></h5>
              <div id="brake_service__abs_and_wheel_bearings">
                <p>When stepping on the brake pedal do you hear noise, does it go away when letting off the brake pedal, if it is grinding or squealing you should have the brake system and wheel bearings checked. If when applying the brake pedal the vehicle feels like it is pulsating under your foot, the rotors need to be replaced or turned. Keep the calipers and sliders lubricated by servicing the brakes regularly. If the ABS light is on, get it scanned and the appropriate repair done.  Get your vehicle's brakes system checked yearly.</p><br/>
              </div><script>$('#brake_service__abs_and_wheel_bearings').hide();</script>
                      <h5><a class="red_link" onclick="$('#shocks_and_struts').slideToggle();">Shocks and Struts</a></h5>
              <div id="shocks_and_struts">
                <p>If your vehicle is very bouncy and makes you feel sea sick, or if when turning the steering wheel it feels like placement steering and sticks, it is time for new shocks/struts. Check your owner's manual for recommended mileage replacement.</p><br/>
              </div><script>$('#shocks_and_struts').hide();</script>
                      <h5><a class="red_link" onclick="$('#oil_changes').slideToggle();">Oil Changes</a></h5>
              <div id="oil_changes">
                <p>A little old school here as we recommend changing oil every 5,000 km. Or, at least once a year for vehicles with less mileage on them. Synthetic oil? Check your owner's manual and see what it recommends. In our harsh climate it is better to change the oil regularly. If the oil is getting black, time to change it. If the oil is low on the dipstick, get an oil change and find out why it is low. Check the oil regularly. Your engine needs clean oil to run more effeciently and last longer.</p><br/>
              </div><script>$('#oil_changes').hide();</script>
                      <h5><a class="red_link" onclick="$('#tire_sales__repairs').slideToggle();">Tire Sales, Repairs</a></h5>
              <div id="tire_sales__repairs">
                <p>We sell, repair,and balance tires. Regular tire rotations are recommended. We can help you fit your vehicle with the tires it needs.</p><br/>
              </div><script>$('#tire_sales__repairs').hide();</script>
                      <h5><a class="red_link" onclick="$('#power_steering').slideToggle();">Power Steering</a></h5>
              <div id="power_steering">
                <p>When you turn the steering wheel is it like arm strong steering, do you hear funny noises or whining, then get your power steering system checked for leaks. Don't let the lines blow out in the winter, get them checked for leaks. Let your vehicle warm up in the cold and you are more likely not to have the lines blow out or wreck the steering rack or the power steering pump by letting the fluid warm up and flow easier.</p><br/>
              </div><script>$('#power_steering').hide();</script>
                      <h5><a class="red_link" onclick="$('#starters').slideToggle();">Starters</a></h5>
              <div id="starters">
                <p>If when turning on the vehicle it doesn't want to start, or slow to turn over or makes noise when turning on the vehicle, you may need your starter replaced or repaired.</p><br/>
              </div><script>$('#starters').hide();</script>
                      <h5><a class="red_link" onclick="$('#alternators').slideToggle();">Alternators</a></h5>
              <div id="alternators">
                <p>If the alternator quits, it causes a loss of power in the vehicle and means the battery doesn't charge and can cause lights to look like they are flickering.</p><br/>
              </div><script>$('#alternators').hide();</script>
                      <h5><a class="red_link" onclick="$('#batteries').slideToggle();">Batteries</a></h5>
              <div id="batteries">
                <p>We sell and install batteries, and when checking the battery we also check the charging system. It is recommended to have the battery serviced, posts cleaned, and checked once a year</p><br/>
              </div><script>$('#batteries').hide();</script>
                      <h5><a class="red_link" onclick="$('#drivetrain').slideToggle();">Drivetrain</a></h5>
              <div id="drivetrain">
                <p>It is a good idea to have the drivetain on your vehicle checked if you feel vibrations, shakes, a rough ride and noises. This includes drive axles, cv boots and joints, u joints and centre bearings. Also, have the transmission oil pan gasket and filter changed and new fluid replaced. </p><br/>
              </div><script>$('#drivetrain').hide();</script>
                      <h5><a class="red_link" onclick="$('#fluid_flushes').slideToggle();">Fluid Flushes</a></h5>
              <div id="fluid_flushes">
                <p>It is important to keep your vehicle's fluids full, clean and keep contaminants out. Check your owner's manual for recommend flushes.</p><br/>
              </div><script>$('#fluid_flushes').hide();</script>
                      <h5><a class="red_link" onclick="$('#windshield_wipers_and_fluid').slideToggle();">Windshield Wipers and Fluid</a></h5>
              <div id="windshield_wipers_and_fluid">
                <p>When we sell you new wiper blades, we install them at no charge. It is important to change the blades regularly to keep your windshield clear in bad weather. Keep the windshield washer fluid full and we recommend using fluid good for -40 year round so that you don't freeze up the washer pump or the blow the lines once it gets cold out.</p><br/>
              </div><script>$('#windshield_wipers_and_fluid').hide();</script>
                      <h5><a class="red_link" onclick="$('#windows_and_doors').slideToggle();">Windows and Doors</a></h5>
              <div id="windows_and_doors">
                <p>If your vehicle has issues with the windows not going up or down, or with electrical systems with no power at the switch, we can trace the problem and perform the necessary repair. We replace door switches and locks and keys.</p><br/>
              </div><script>$('#windows_and_doors').hide();</script>
                      <h5><a class="red_link" onclick="$('#switches___sensors').slideToggle();">Switches & Sensors</a></h5>
              <div id="switches___sensors">
                <p>We diagnose, trace wiring and repair as required. This includes many different switches and sensors in a vehicle, including ignition switches as an example.</p><br/>
              </div><script>$('#switches___sensors').hide();</script>
                      <h5><a class="red_link" onclick="$('#vehicle_check-over').slideToggle();">Vehicle Check-over</a></h5>
              <div id="vehicle_check-over">
                <p>Haven't had your vehicle looked over for awhile, going on a road trip, just want to make sure it is in good working order? We can check it over for you and advise if your vehicle requires any work or not and give you peace of mind while driving. </p><br/>
              </div><script>$('#vehicle_check-over').hide();</script>
                      <h5><a class="red_link" onclick="$('#winter_and_spring_check-ups').slideToggle();">Winter and Spring Check-ups</a></h5>
              <div id="winter_and_spring_check-ups">
                <p>Have your vehicles tires, fluids, belts, hoses, lights, clamps, air filters, alternator, battery, starter and fluids checked. A good idea to do before winter and start of spring.</p><br/>
              </div><script>$('#winter_and_spring_check-ups').hide();</script>
                      <h5><a class="red_link" onclick="$('#purchase_or_selling_check-over').slideToggle();">Purchase or Selling Check-over</a></h5>
              <div id="purchase_or_selling_check-over">
                <p>Good idea for anyone buying or selling a vehicle. That way you get a report of the vehicle's condition to enable you to make informed decisions. </p><br/>
              </div><script>$('#purchase_or_selling_check-over').hide();</script>
                      <h5><a class="red_link" onclick="$('#mist_service').slideToggle();">Mist Service</a></h5>
              <div id="mist_service">
                <p>An excellent way to clean allergens out of your heating system and vehicle interior. Makes it a healthier way to breathe the air inside your vehicle.</p><br/>
              </div><script>$('#mist_service').hide();</script>
                      <h5><a class="red_link" onclick="$('#headlight_and_bulb_replacements').slideToggle();">Headlight and Bulb Replacements</a></h5>
              <div id="headlight_and_bulb_replacements">
                <p>We check your vehicle's lights with every oil change. It is important to have them checked and bulbs replaced in order keep you vehicle visibile to others and so you can see clearly. Also, if the head lamp lenses are getting dull and scratched, we can polish them.</p><br/>
              </div><script>$('#headlight_and_bulb_replacements').hide();</script>
                      <h5><a class="red_link" onclick="$('#airfilters').slideToggle();">Airfilters</a></h5>
              <div id="airfilters">
                <p>If the air filter/breather gets dirty, it reduces fuel effiecincy and engine performance. We check them with every oil change and recommend they be checked at least once a year.</p><br/>
              </div><script>$('#airfilters').hide();</script>
                      <h5><a class="red_link" onclick="$('#fuel_pumps_and_filters').slideToggle();">Fuel Pumps and Filters</a></h5>
              <div id="fuel_pumps_and_filters">
                <p>If your vehicle's fuel filter is not in the tank, it is recommended to change them regularly. If the fuel filter and pump get dirty and clogged up, then the vehicle will not perform well and may stall or not want to start.</p><br/>
              </div><script>$('#fuel_pumps_and_filters').hide();</script>
                      <h5><a class="red_link" onclick="$('#engine_cooling_system').slideToggle();">Engine Cooling System</a></h5>
              <div id="engine_cooling_system">
                <p>It is just as important in the summer for the antifreeze to be full and clean as it is in the winter. This helps your vehicle operate more efficiently. We can test the antifreeze and keep it up to strength and clean for you.</p><br/>
              </div><script>$('#engine_cooling_system').hide();</script>
                      <h5><a class="red_link" onclick="$('#heating_system').slideToggle();">Heating System</a></h5>
              <div id="heating_system">
                <p>If your heater blower motor is making noise when using it or if not all the air speeds are working proper, then your heating system needs to be looked at. Also, if your vehicle is equipped with a cabin air filter, have it replaced once a year to keep the air you breathe clean and not have the air flow restricted when using the heat or the air conditioning.</p><br/>
              </div><script>$('#heating_system').hide();</script>
                    <br/><br/>
            <p>If you have any questions, give us a call  at <a href="13065250559">1.306.525.0559</a>, ask for Faye!</p><br/>
        </div>

      </div>
    
    </div>);

}
  
export default Services;