

const pages = [
  {id: "about", name: "About Us", category: 'General', href:'/', header: true, footer: true, logged_in_only: false, logged_out_only: false},
  {id: "services", name: "Services", category: 'General', href:'/services', header: true, footer: true, logged_in_only: false, logged_out_only: false},
  {id: "hours_and_location", name: "Hours & Location", category: 'General', href:'/hours_and_location', header: true, footer: true, logged_in_only: false, logged_out_only: false},
  {id: "facebook", name: "Facebook", category: 'General', href:'https://www.facebook.com/profile.php?id=100057441759632', header: true, footer: true, logged_in_only: false, logged_out_only: false},
];





var apiUrl = 'http://api.actionexpress.co';



const loadingPollingIntervalSecondsFraction = 4; // 1/10th


export {
  loadingPollingIntervalSecondsFraction,
  pages,
  apiUrl
}