import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import * as vars from './../vars.js';
import Button from 'react-bootstrap/Button';
//import Carousel from 'react-bootstrap/Carousel';

import { motion, useAnimate } from "framer-motion"

const google = window.google;

function HoursAndLocation(props) {


  var [render, setRender] = useState(0);

  
  useEffect(() => {
    
    //console.log(cats);


        // Initialize and add the map
      let map;

      async function initMap() { // 50.451765098921186, -104.59544351028585
        const position = { lat: 50.451765098921186, lng: -104.59544351028585 };
        // Request needed libraries.
        //@ts-ignore
        const { Map } = await google.maps.importLibrary("maps");
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

        // The map, centered at Uluru
        map = new Map(document.getElementById("map_large"), {
          zoom: 14,
          center: position,
          mapId: "ActionExpressHQ_Map",
        });

        // The marker, positioned at Uluru
        const marker = new AdvancedMarkerElement({
          map: map,
          position: position,
          title: "AE_HQ",
        });
      }
      
       initMap();
    
  });


  

  return (
    <div className="page">

      <div className="center_box">
        <div className="center_box_heading">HOURS & LOCATION</div>
        <div className="center_box_text center_contact_text">
          <h4><b>Open 8am to 5pm, Monday to Friday</b></h4><br/>
          <h2><a className="black_link" href="tel://1-306-525-0559">PH. 1.306.525.0559</a><br/></h2>
          <h2><a className="black_link">FX. 1.306.569.7487</a></h2><br/>
          <h5><a className="red_link" href="https://www.google.com/maps/dir//rudy's+brakes+and+steering+regina/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x531c1e4b0e3d614f:0x41a75f32d987148f?sa=X&ved=2ahUKEwiShaa-g5voAhW6mXIEHYFYBkkQ9RcwC3oECBIQDg">RUDY's BRAKES AND STEERING (1998) LTD.</a></h5>
          <h6>1700 Winnipeg Street</h6>
          <h6>Regina, SK</h6>
          <h6>S4P 1E9</h6><br/>
          <p><i>At the corner of Saskatchewan Drive and Winnipeg Street</i></p><br/>
          
        </div>
        <div id="map_large"></div>
      </div>


      <div className="center_box">
        <div className="center_box_heading">PAYMENT METHODS</div>
        <div className="center_box_text">
            <p>We accept cash, Interac, Visa, Master Card and American Express.</p><br/>
            <img src="images/payments.png" width="100%" />
        </div>
      </div>
     
      
    
    </div>);

}
  
export default HoursAndLocation;